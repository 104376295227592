import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling?: GqlErrorHandlingOptions;
  enabled?: boolean;
};

export default function useHandbookSectionsGet(options?: Options) {
  async function queryFn() {
    const res = await GqlGetHandbookSections({});
    return res.handbookSections;
  }

  const context = useQuery({
    queryKey: [QUERY_KEYS.handbookSections],
    queryFn,
    enabled: options?.enabled
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
